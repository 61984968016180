
import { Button, Card, Grid, Typography } from "@mui/material";
import { useReducer, useState, useEffect, useMemo } from "react";
import DataTable from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { FLEET_APIS } from "../../../common/apis";
import { SearchIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";

export default function SameSerialUnit({ userData, makesList }) {
    const user = useUser();
    const { get } = useFetch();

    const [dropdownData, setDropdownData] = useState([])
    const [makesData, setMakesData] = useState([])
    const [dataTableData, setDataTableData] = useState([])
    const [data, dispatch] = useReducer(
        SearchFilterReducer,
        {
            UnitMake: userData.UnitMake,
            UnitModelFormatted: userData.UnitModelFormatted,
            Serial: userData.Serial,
            userName: user.Username,
        }
    );

    useEffect(() => {
        (async () => {
            const data = await get(`${FLEET_APIS.UNIT_MODELS}?unitMake=${userData?.UnitMake}`, { skipBlocker: true });
            setDropdownData(data || []);

            const dtData = await get(`${FLEET_APIS.UNIT_MODELS_SERIAL}?UnitMake=${userData.UnitMakeId}&Make=${userData.UnitMake}&Serial=${userData.Serial}&UseStartsWith=${false}`);
            setDataTableData(dtData || []);
        })();
        setMakesData(makesList)
    }, []);

    useEffect(() => {
        (async () => {
            const dataList = await get(`${FLEET_APIS.UNIT_MODELS}?unitMake=${data?.UnitMake}`, { skipBlocker: true });
            setDropdownData(dataList || []);
        })();
    }, [data?.UnitMake]);

    //call api for search audit data
    function search() {
        (async function () {
            const dtData = await get(`${FLEET_APIS.UNIT_MODELS_SERIAL}?UnitMake=${data.UnitMake}&Make=${data.UnitMake}&Serial=${data.Serial}&UseStartsWith=${false}`);
            setDataTableData(dtData || []);
        })();
    }

    const tblOptions = {
        columns: GetColumns(),
        dataSource: (dataTableData || []),
        rowIdProperty: "UnitId",
        // isDisableColumnFilter: true,
        sx: { height: 250 },
    };
    const GetFormSections = useGetFormSections(search, data, dropdownData, makesData)

    return (
        <>
            <Grid container sx={{ minWidth: "75vw" }}>
                <Grid item xs={12}>
                    <Card sx={{ p: 1, }}>
                        <Form
                            sections={GetFormSections}
                            data={data}
                            dispatch={dispatch}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        sx={{ marginLeft: "5px", marginTop: "10px" }}
                    >
                        Total Records: <b>{dataTableData && dataTableData.length}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable {...tblOptions} />
                </Grid>
            </Grid>
        </>
    );
}

function GetColumns() {
    return [
        {
            headerName: "Product",
            field: "Product",
            minWidth: 150,
        },
        {
            headerName: "Customer",
            field: "Customer",
            minWidth: 150,
            flex: 1
        },
        {
            headerName: "Unit",
            field: "Unit",
            minWidth: 150,
        },
        {
            headerName: "Make",
            field: "UnitMake",
            minWidth: 150,
        },
        {
            headerName: "Model",
            field: "UnitModel",
            minWidth: 200,
        },
        {
            headerName: "Serial",
            field: "Serial",
            minWidth: 120,
        },
        {
            headerName: "Predelivery",
            field: "Predelivery",
            width: 80,
        },
        {
            headerName: "Parked",
            field: "Parked",
            width: 80,
        },
        {
            headerName: "Scrapped",
            field: "Scrapped",
            width: 80,
        },
        {
            headerName: "Archived",
            field: "Archived",
            width: 80,
        },
    ];
}

const useGetFormSections = (search, data, dropdownData, makesData) => {
    return useMemo(
        () => [
            {
                fluid: true,
                fields: [
                    {
                        xsCol: 12,
                        group: [
                            {
                                xsCol: 3,
                                name: "UnitMake",
                                label: "Make",
                                type: "dropdown",
                                selectOptions: (makesData || []).map((x) => ({
                                    value: x.UnitMake,
                                    text: x.UnitMake,
                                })),
                            },
                            {
                                xsCol: 3,
                                name: "UnitModelFormatted",
                                label: "Model",
                                type: "dropdown",
                                selectOptions: (dropdownData || []).map((x) => ({
                                    value: x.UnitModelFormatted,
                                    text: x.UnitModelFormatted,
                                })),
                            },
                            {
                                xsCol: 3,
                                name: "Serial",
                                label: "Serial",
                            },
                            {
                                alignContent: "end",
                                component: function () {
                                    return (
                                        <Button
                                            variant="contained"
                                            onClick={search}
                                            disabled={data?.Make == "" && data?.Model == "" && data?.Serial == "" && data?.FleetNumber == "" ? true : false}
                                            startIcon={< SearchIcon />}
                                        >
                                            Check
                                        </Button >
                                    );
                                },
                            },
                        ],
                    },
                ],
            },
        ], [dropdownData])
}

function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    return state;
}
