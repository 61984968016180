import { Button, Checkbox, FormControlLabel, Grid, Typography, } from "@mui/material";
import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useEffect } from "react";
import appResources from "../../../app-resources/en/Resources.json";
import { SaveIcon } from "../../../icons/ButtonIcons";
import { MANAGEMENT_APIS, MASTER_DATA_APIS } from "../../../common/apis";

const headingMessage = appResources.FLUID_NOTIFICATIONS_MESSAGE;
const successMessage = { color: "green", message: "Your fluid type notifications have been updated" };
const errorMessage = { color: "purple", message: "No changes were made to your fluid type notifications" };

export default function FluidNotifications() {
  const { get, post } = useFetch();

  const [checkedItems, setCheckedItems] = useState([]);
  const [infoMessage, setInfoMessage] = useState({})
  const [fluidTypes, setFluidTypes] = useState([]);

  const getData = async () => {
    let resData = await get(MANAGEMENT_APIS.USER_FLUID_TYPE_NOTIFICATIONS);
    if (resData?.length > 0) {
      let a = resData?.map(item => { return { ...item, IsDefault: true, IsExisting: true } })
      setCheckedItems(a);
      localStorage.setItem("ftn", JSON.stringify(a));
    }
    else { localStorage.setItem("ftn", JSON.stringify([])); }
  }
  useEffect(() => {
    (async function () {
      let responseLabData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=FluidTypes`);
      if (responseLabData?.FluidTypes) {
        // responseLabData?.FluidTypes.unshift({
        //   FluidTypeId: 0,
        //   FluidType: "All Types",
        // });
        setFluidTypes(responseLabData?.FluidTypes);
      }
    })();
    getData()
  }, []);

  function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.FluidTypeId === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  const handleRoleChange = (fluidTypeId, isChecked) => {
    removeObjectWithId(checkedItems, fluidTypeId);

    setCheckedItems((prevCheckedItems) => ([
      ...prevCheckedItems,
      { "FluidTypeId": fluidTypeId, "IsDefault": isChecked, }
    ]));
  };

  let compareTwoArrayOfObjects = (arr1, arr2) => {
    const res1 = arr1?.filter((page1) => arr2?.find(page2 => page1.FluidTypeId === page2.FluidTypeId))
    return res1
  };

  const handleSaveClick = async () => {
    let ftn = localStorage.getItem("ftn");
    let ids = []
    let newArray = [];
    let results = [];
    let isExistingArr = []
    ftn = JSON.parse(ftn)
    const a = compareTwoArrayOfObjects(checkedItems, ftn);

    if (a?.length > 0) {
      isExistingArr = a.map(item => { return { ...item, IsExisting: true } })
      ids = new Set(isExistingArr.map(item => { return (item.FluidTypeId) }));
      newArray = checkedItems.filter(obj => !ids.has(obj.FluidTypeId));
      results = [...isExistingArr, ...newArray];
      let resData = await post(MANAGEMENT_APIS.MANAGE_FLUID_TYPE_NOTIFICATIONS, results);
      if (resData > 0) {
        setInfoMessage(successMessage)
        return getData()
      }
      if (resData < 1) {
        setInfoMessage(errorMessage)
        return getData()
      }
      return;
    }
    if (checkedItems?.length > 0) {
      let resData = await post(MANAGEMENT_APIS.MANAGE_FLUID_TYPE_NOTIFICATIONS, checkedItems);
      if (resData > 0) {
        setInfoMessage(successMessage)
        return getData()
      }
      if (resData < 1) {
        setInfoMessage(errorMessage)
        return getData()
      } return;
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 600 }}>{headingMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: infoMessage?.color || "" }}>{infoMessage?.message || ""}</Typography>
          </Grid>
          {fluidTypes?.map((ft) => {
            return (
              <Grid item xs={12} key={ft.FluidTypeId} sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={ft.FluidType}
                      checked={checkedItems?.filter(item => item?.FluidTypeId === ft.FluidTypeId)[0]?.IsDefault === true ? true : false}
                      onChange={(e) => handleRoleChange(ft.FluidTypeId, e.target.checked)}
                      sx={{
                        color: checkedItems[ft.FluidTypeId]
                          ? "green"
                          : "initial",
                      }}
                    />
                  }
                  label={ft.FluidType + (ft.IsNonFluid ? " ( Non-Fluid )" : "")}
                  sx={{
                    width: "50%",
                    backgroundColor: "transparent",
                  }}
                  style={{
                    backgroundColor: (checkedItems?.filter(item => item?.FluidTypeId === ft.FluidTypeId)[0]?.IsDefault === true)
                      ? "green"
                      : "transparent",
                    color: (checkedItems?.filter(item => item?.FluidTypeId === ft.FluidTypeId)[0]?.IsDefault === true)
                      ? "white" : "initial",
                  }}
                />
              </Grid>)
          })}
          <Grid item xs={12}>
            <Button
              sx={{ ml: 50 }}
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
