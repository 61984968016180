import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "../../../components/Form";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import { MASTER_DATA_APIS, TRAY_APIS } from "../../../common/apis";
import { AddIcon } from "../../../icons/ButtonIcons";
import useFetch from "../../../hooks/useFetch";
import AppDialog from "../../../components/AppDialog";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../components/DatatableCellExpand";
import ConfirmModal from "../../../components/ConfirmModal";

export default function StandardNames({
  recordSelected,
  cancel,
}) {
  const { CanDoDataMaintenance, CanAdministrate } = useRoot();
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  // const curTheme = useTheme();
  const { get } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const [namesData, setNamesData] = useState([]);
  const [fluidsDropdownData, setFluidsDropdownData] = useState([]);
  const [dialog, setDialog] = useState({
    title: "",
    actionName: "",
    open: false,
    message: "",
  });

  const [rowData, setRowData] = useState({});

  async function loadData() {
    let responseLabData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=FluidTypes`);
    if (responseLabData.FluidTypes) {
      responseLabData.FluidTypes.unshift({
        FluidTypeId: 0,
        FluidType: "All Types",
      });
      setFluidsDropdownData(responseLabData.FluidTypes);
    }
    try {
      let responseNamesData = await get(`${TRAY_APIS.COMPONENT_NAMES}`, {
        skipBlocker: true,
      });

      setNamesData(responseNamesData);
    }
    catch {
      setNamesData([]);
    }
  }

  const filteredData = useMemo(
    () =>
      data.FluidTypeId !== 0
        ? namesData?.filter((item) => {
          return data.FluidTypeId === item.FluidTypeId;
        })
        : namesData,
    [namesData, data.FluidTypeId]
  );

  //call api for search audit data
  async function addClick() {
    setDialog({
      actionName: (addClickAlert),
      title: "Add Standard Name",
      open: true,
      message: `Do you wish to add new to the list of standard component names?`,
    });
  }

  async function addClickAlert() {
    const response = await get(`${TRAY_APIS.ADD_COMPONENT_NAME}?FluidTypeId=${data.FluidTypeId === 0 ? 1 : data?.FluidTypeId}&Component=${data?.ComponentName}`);
    if (response > 0) {
      dispatch({ type: FormActions.RESET_FORM });
    }
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
    await loadData();
  }

  const tblOptions = {
    columns: GetColumns(deleteClick, selectClick, CanDoDataMaintenance, CanAdministrate),
    dataSource: filteredData || [],
    rowIdProperty: "ComponentNameId",
    isDisableColumnFilter: true,
    sx: { height: 300 },
  };

  function deleteClick(row) {
    setDialog({
      actionName: (deleteClickAlert),
      title: "Delete Standard Name",
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }

  async function deleteClickAlert() {
    const response = await get(`${TRAY_APIS.DELETE_COMPONENT_NAMES}?ComponentNameId=${rowData.ComponentNameId}`);
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    await loadData();
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }

  function selectClick(row) {
    recordSelected(row);
    cancel && cancel()
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 1, minWidth: "800px" }}>
            <Form
              sections={GetFormSections(
                fluidsDropdownData,
                addClick,
                CanDoDataMaintenance,
                data
              )}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false, }));
          }} alertAction={dialog?.actionName}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick, selectClick, CanDoDataMaintenance, CanAdministrate) {
  return [
    CanDoDataMaintenance && {
      headerName: "Select",
      field: "Select",
      sortable: false,
      width: 90,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      width: 90,
      hide: !CanAdministrate,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },

    {
      headerName: "New Comp",
      field: "ComponentName",
      minWidth: 170,
      renderCell: renderCellExpand,
      flex: 1
    },
    {
      headerName: "Type",
      field: "FluidType",
      minWidth: 170,
    },
  ];
}

function GetFormSections(fluidsDropdownData, addClick, CanAdministrate, data) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "ComponentName",
          label: "Name",
          xsCol: 3,
        },

        {
          name: "FluidTypeId",
          label: "Fluid Type",
          type: "dropdown",
          xsCol: 3,
          required: true,
          selectOptions: (fluidsDropdownData || []).map((x) => ({
            value: x.FluidTypeId,
            text: x.FluidType,
          })),
        },

        CanAdministrate && {
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={addClick}
                sx={{ mt: "14px" }}
                startIcon={<AddIcon />}
                disabled={data?.ComponentName?.length < 1}
              >
                Add
              </Button>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchFilterDefaults = {
  FluidTypeId: 1,
  ComponentName: "",
};
