import { colors } from "@mui/material";
import Constants from "../common/Constants";

export default function useTableStyles() {
  //const theme = useTheme();
  return {
    "&.MuiDataGrid-root": {
      "& .row-status--DodgerBlue": {
        color: colors.common.white,
        backgroundColor: "#253FC8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--Green": {
        color: colors.common.white,
        backgroundColor: "#22925C",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--DarkOrange": {
        color: colors.common.white,
        backgroundColor: "#22925C",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--DeepPink": {
        color: colors.common.white,
        backgroundColor: "#E7218D",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--Red": {
        color: colors.common.white,
        backgroundColor: "#CC0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .row-status--hold": {
        color: colors.common.white,
        backgroundColor: "#cca026",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--IsArchived": {
        color: colors.common.white,
        backgroundColor: "#808080",
      },
      "& .row-status--normal": {
        color: colors.common.white,
        backgroundColor: "#22925c",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .row-status--caution": {
        color: colors.common.white,
        backgroundColor: "#f37021",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--query": {
        color: colors.common.white,
        backgroundColor: "#253fc8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--action": {
        color: colors.common.white,
        backgroundColor: "#e7218d",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--critical": {
        color: colors.common.white,
        backgroundColor: "#cc0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .row-status--normal-r": {
        color: "black",
        backgroundColor: "#99ddb8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .row-status--caution-r": {
        color: "black",
        backgroundColor: "#ffe2bf",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--query-r": {
        color: "black",
        backgroundColor: "#c8e3ff",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--action-r": {
        color: "black",
        backgroundColor: "#ffc6e6",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .row-status--critical-r": {
        color: "black",
        backgroundColor: "#ffbfbf",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .cell-status--normal": {
        color: colors.common.white,
        backgroundColor: "#008000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .cell-status--caution": {
        color: colors.common.white,
        backgroundColor: "#ff8c00",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--query": {
        color: colors.common.white,
        backgroundColor: "#1e90ff",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--action": {
        color: colors.common.white,
        backgroundColor: "#ff1493",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--critical": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--black": {
        color: colors.common.white,
        backgroundColor: "#000000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--gray": {
        color: colors.common.white,
        backgroundColor: "#a5abaf",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--blue": {
        color: colors.common.white,
        backgroundColor: "#253fc8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .cell-status--brown": {
        color: colors.common.white,
        backgroundColor: "#612d0d",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--steelblue": {
        color: colors.common.white,
        backgroundColor: "#0086b4",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--light-blue": {
        color: colors.common.white,
        backgroundColor: "#00bfff",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--dodgerblue": {
        color: colors.common.white,
        backgroundColor: "#3366cc",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--conrflowerblue": {
        color: colors.common.white,
        backgroundColor: "#6495ED",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--navy": {
        color: colors.common.white,
        backgroundColor: "#000080",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--goldenrod": {
        color: colors.common.white,
        backgroundColor: "#cca026",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--red": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--violetred": {
        color: colors.common.white,
        backgroundColor: "#c71585",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--Red": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--crimson": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--pink": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--deeppink": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--orange": {
        color: colors.common.white,
        backgroundColor: "#f37021",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--teal": {
        color: colors.common.white,
        backgroundColor: "#008080",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--darkorange": {
        color: colors.common.white,
        backgroundColor: "#f37021",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--purple": {
        color: colors.common.white,
        backgroundColor: "#800080",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--fuchsia": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--qc-green": {
        color: "black",
        backgroundColor: "#99ddb8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--light-green": {
        color: colors.common.white,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--light-green-dashboard": {
        color: colors.common.black,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.black,
        },
      },
      "& .cell-status--mediumseagreen": {
        color: colors.common.white,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--green": {
        color: colors.common.white,
        backgroundColor: "#009933",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--white": {
        color: colors.common.black,
        backgroundColor: "#ffffff",
        "& .MuiSvgIcon-root": {
          color: colors.common.black,
        },
      },

      "& .cell-status--forestgreen": {
        color: colors.common.white,
        backgroundColor: "#228b22",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--autoeval": {
        color: colors.common.white,
        backgroundColor: "#daa520",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .cell-status--newversion": {
        textDecoration: "line-through",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--goldenrod": {
        color: colors.common.white,
        backgroundColor: "#cca026",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--red": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--violetred": {
        color: colors.common.white,
        backgroundColor: "#c71585",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--crimson": {
        color: colors.common.white,
        backgroundColor: "#ff0000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--pink": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--white": {
        color: colors.common.black,
        backgroundColor: "#ffffff",
        "& .MuiSvgIcon-root": {
          color: colors.common.black,
        },
      },
      "& .Mui-selected .cell-status--deeppink": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--orange": {
        color: colors.common.white,
        backgroundColor: "#f37021",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--teal": {
        color: colors.common.white,
        backgroundColor: "#008080",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--darkorange": {
        color: colors.common.white,
        backgroundColor: "#f37021",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--purple": {
        color: colors.common.white,
        backgroundColor: "#800080",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--fuchsia": {
        color: colors.common.white,
        backgroundColor: "#ec4cad",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .Mui-selected .cell-status--light-green": {
        color: colors.common.white,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--light-green-dashboard": {
        color: colors.common.black,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.black,
        },
      },
      "& .Mui-selected .cell-status--mediumseagreen": {
        color: colors.common.white,
        backgroundColor: "#99e866",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--green": {
        color: colors.common.white,
        backgroundColor: "#009933",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },

      "& .Mui-selected .cell-status--forestgreen": {
        color: colors.common.white,
        backgroundColor: "#228b22",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--black": {
        color: colors.common.white,
        backgroundColor: "#000000",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--gray": {
        color: colors.common.white,
        backgroundColor: "#a5abaf",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--blue": {
        color: colors.common.white,
        backgroundColor: "#253fc8",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--brown": {
        color: colors.common.white,
        backgroundColor: "#612d0d",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--steelblue": {
        color: colors.common.white,
        backgroundColor: "#0086b4",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--light-blue": {
        color: colors.common.white,
        backgroundColor: "#00bfff",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
      "& .Mui-selected .cell-status--dodgerblue": {
        color: colors.common.white,
        backgroundColor: "#3366cc",
        "& .MuiSvgIcon-root": {
          color: colors.common.white,
        },
      },
    },
  };
}

export function useRowColorResolver() {
  return {
    evalSample: function (sample) {
      if (sample.HoldType) return "hold";
      if (sample?.NewVersion > 0) return "IsArchived";
      if ((sample.AlertStatusId > -1) && (sample.AlertStatus?.length > 0) && sample.Step > 5) {
        return (sample.AlertStatus.toLowerCase());
      }
      return "";
    },
    sample: function (sample) {
      if (sample.HoldType) return "hold";
      if ([1, 2, 3, 4, 5, 6, 14, 15, 16, 17, 18].indexOf(sample.AlertStatusId) > -1) {
        return {
          2: "normal",
          3: "query",
          15: "query",
          4: "caution",
          16: "caution",
          5: "action",
          17: "action",
          6: "critical",
          18: "critical",
          14: "normal",
        }[sample.AlertStatusId];
      }
      return "";
    },
    resultSample: function (sample) {
      if (sample.HoldType) return "hold";
      if ((sample.AlertStatusId > -1) && sample.AlertStatus?.length > 0) {
        return (sample.AlertStatus.toLowerCase() + "-r");
      }
      return "";
    },
    testItems: function (sample) {
      if (sample?.IsArchived) {
        return "IsArchived";
      }
      return "";
    },
  };
}

export function useCellColorResolver() {
  return {
    sampleResultEval: function ({ field, row }) {
      if (field === "SampleNumber") {
        if (row.SampleNumber)
          if (row.HoldType) return "hold";
        if ((row.AlertStatusId > -1) && (row.AlertStatus?.length > 0)) {
          return (row.AlertStatus.toLowerCase());
        }
        return "white";
      }
      if (field === "AutoEvaluated") {
        if (row.AutoEvaluated === "?") return "autoeval";
      }
      if (field === "Step") {
        if (row.Step === 5) return "conrflowerblue";
      }
      if (row[field + "_valReq"]) return "red";
      if (row[field + "_hasVal"]) return "green";
      return "";
    },
    sampleResult: function ({ field, row }) {
      if (field === "SampleNumber") {
        if (row?.NewVersion > 0) return "newversion";
        if (row.SampleNumber) return "white";
      }
      if (field === "Product" && row?.ProductColour) {
        return row.ProductColour.toLowerCase();
      }
      if (field === "AutoEvaluated") {
        if (row.AutoEvaluated === "?") return "autoeval";
      }
      if (field === "Step") {
        if (row.Step === 5) return "conrflowerblue";
      }
      if (row[field + "_valReq"]) return "red";
      if (row[field + "_hasVal"]) return "qc-green";
      return "";
    },
    autoEvaluation: function ({ field, row }) {
      if (field === "AlertsPercent") {
        if (row.AlertsPercent <= 15) return "red";
        if (row.AlertsPercent <= 25) return "pink";

        if (row.AlertsPercent >= 85) return "green";
        if (row.AlertsPercent >= 75) return "light-green";
      } else if (field === "ErrorsPercent") {
        if (row.ErrorsPercent >= 25) return "red";
        if (row.ErrorsPercent >= 15) return "pink";
      } else if (field === "AgreePercent") {
        if (row.AgreePercent >= 85) return "green";
        if (row.AgreePercent >= 75) return "light-green";
      } else if (field === "AgreeNormalPercent") {
        if (row.AgreeNormalPercent >= 85) return "green";
        if (row.AgreeNormalPercent >= 75) return "light-green";
      } else if (field === "AgreeNotNormalPercent") {
        if (row.AgreeNotNormalPercent >= 85) return "green";
        if (row.AgreeNotNormalPercent >= 75) return "light-green";
      } else if (field === "DisagreePercent") {
        if (row.DisagreePercent >= 25) return "red";
        if (row.DisagreePercent >= 15) return "pink";
      } else if (field === "DisagreeNormalPercent") {
        if (row.DisagreeNormalPercent >= 25) return "red";
        if (row.DisagreeNormalPercent >= 15) return "pink";
      } else if (field === "DisagreeNotNormalPercent") {
        if (row.DisagreeNotNormalPercent >= 25) return "red";
        if (row.DisagreeNotNormalPercent >= 15) return "pink";
      }
      return "";
    },
    trayDashboard: function ({ field, row }) {
      if (field === "Label") {
        return "black";
      } else if (field === "MinimumStep") {
        if (row.MinimumStep === Constants.completedStep) return "green";
        if (
          row.MinimumStep < Constants.completedStep &&
          row.MinimumStep > Constants.evalStep
        )
          return "blue";
      } else if (field === "MaximumStep") {
        if (row.MaximumStep === Constants.completedStep) return "green";
        if (
          row.MaximumStep < Constants.completedStep &&
          row.MaximumStep > Constants.evalStep
        )
          return "blue";
      } else if (field === "MissingResults") {
        if (row.MaximumStep >= 3 && row.MissingResults > 0) return "red";
        if (row.MaximumStep >= 3 && row.MissingResults === 0) return "green";
      } else if (field === "CriticalLogs") {
        if (row.MaximumStep > 6 && row.CriticalLogs > 0) return "pink";
      }
      return "";
    },
    cleanUp: function ({ field, row }) {
      if (field === "Samples") {
        if (row.Samples > 0) return "red";
      } else if (field === "KOWA") {
        if (row.KOWA > 0) return "red";
      } else if (field === "Labcheck") {
        if (row.Labcheck > 0) return "red";
      } else if (field === "KowaArchive") {
        if (row.KowaArchive > 0) return "red";
      } else if (field === "LabcheckArchive") {
        if (row.LabcheckArchive > 0) return "red";
      }
      return "";
    },
    product: function ({ field, row }) {
      if (field === "Product" && row.Colour) {
        return row.Colour.toLowerCase();
      } else if (field === "CanAutoEvaluate") {
        if (row.CanAutoEvaluate) return "purple";
      } else if (field === "NoEvaluations") {
        if (!row.NoEvaluations) return "purple";
      } else if (field === "IsArchived") {
        if (row.IsArchived) return "gray";
      } else if (field === "HideProduct") {
        if (row.HideProduct) return "red";
      }
      return "";
    },
    siteSetProduct: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      }
      return "";
    },
    laboratory: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "SampleNumber") {
        if (row.SampleNumber && row.HoldType) return "white";
      } else if (field === "Step") {
        if (row.Step <= 5) return "light-blue";
      }
      return "";
    },
    sampleBackGroundWhite: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      }
      else if (field === "Step") {
        if (row.Step === 1) return "light-blue";
      }
      return "";
    },
    criticalLogs: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "AlertStatus") {
        if (row.AlertColour) return row.AlertColour?.toLowerCase();
      }
      return "";
    },
    componentRecentSamples: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "LastAlertStatus") {
        if (row.LastAlertColour) return row.LastAlertColour?.toLowerCase();
      }
      return "";
    },
    contactExports: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "SampleNumber") {
        if (row.AlertColour) return row.AlertColour?.toLowerCase();
      } else if (field === "BatchAdded") {
        return "green";
      } else if (field === "IsSampleReport") {
        return "purple";
      } else if (field === "IsSMS") {
        return "purple";
      }
      return "";
    },

    contactsPrimaryMappings: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      }
      return "";
    },

    laboratories: function ({ field, row }) {
      if (field === "IsDefault") {
        if (row.IsDefault) return "purple";
      } else if (field === "IsForImporting") {
        if (row.IsForImporting) return "purple";
      }
      return "";
    },

    autoEvaluate: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "SampleNumber") {
        if (row.AlertColour !== null) return row.AlertColour.toLowerCase();
      } else if (field === "JpAlertStatus") {
        if (row.JpAlertColour !== null) return row.JpAlertColour.toLowerCase();
      } else if (field === "LocalAlertStatus") {
        if (row.LocalAlertColour !== null)
          return row.LocalAlertColour.toLowerCase();
      } else if (field === "JpComplete") {
        if (row.JpIsComplete) return "purple";
      } else if (field === "LocalComplete") {
        if (row.LocalIsComplete) return "purple";
      } else if (field === "Status") {
        if (row.StatusId === 2) return "teal";
        else if (row.StatusId === 3) return "red";
        else if (row.StatusId === 4) return "forestgreen";
      }
      return "";
    },

    usersOverview: function ({ field, row }) {
      if (field === "Adminstrator") {
        if (row.RoleAdministration) return "green";
      } else if (field === "Registration") {
        if (row.RoleRegistration) return "purple";
      } else if (field === "Laboratory") {
        if (row.RoleLaboratory) return "purple";
      } else if (field === "QualityCheck") {
        if (row.RoleQualityCheck) return "purple";
      } else if (field === "Evaluation") {
        if (row.RoleEvaluation) return "purple";
      } else if (field === "Templates") {
        if (row.RoleMachineTemplates) return "purple";
      } else if (field === "RoleTestSets") {
        if (row.RoleTestSets) return "purple";
      } else if (field === "SpecialReports") {
        if (row.RoleSpecialReports) return "purple";
      } else if (field === "RoleImportHistory") {
        if (row.RoleImportHistory) return "purple";
      } else if (field === "IsNotifyActionsCriticals") {
        if (row.IsNotifyActionsCriticals) return "purple";
      } else if (field === "BlockFeedback") {
        if (row.IsBlockFeedback) return "red";
        if (!row.IsBlockFeedback) return "green";
      } else if (field === "Developer") {
        if (row.RoleDeveloper) return "green";
      }
      return "";
    },

    searchContact: function ({ field, row }) {
      if (field === "IsArchived") {
        if (row.IsArchived) return "red";
      } else if (
        field === "IsKomatsu" ||
        field === "IsPortalUser" ||
        field === "IsLabcheckUser"
      ) {
        if (row.IsKomatsu) return "blue";
        if (row.IsPortalUser) return "orange";
        if (row.IsLabcheckUser) return "green";
        else return "brown";
      } else if (field === "ContactRole") {
        if (row.ContactRole === "Superuser") return "purple";
      }
      return "";
    },
    accessPermissionOverviewForm: function ({ field, row }) {
      if (field === "Archived") {
        if (row.Archived === "Yes") return "red";
      } else if (field === "Source") {
        if (row.IsKomatsu) return "blue";
        if (row.IsPortalUser) return "orange";
        if (row.IsLabcheckUser) return "green";
        else return "brown";
      } else if (field === "ContactRole") {
        if (row.ContactRole === "Superuser") return "purple";
      }
      if (field === "Primary") {
        if (row.Primary === "Yes") return "green";
      }
      if (field === "Weekly") {
        if (row.Weekly === "Yes") return "green";
      }
      if (field === "Monthly") {
        if (row.Monthly === "Yes") return "green";
      }
      return "";
    },

    productCommonCellColour: function ({ field, row }) {
      if (field === "Product" && (row.Colour || row.ProductColour)) {
        return row.Colour?.toLowerCase() || row.ProductColour?.toLowerCase();
      }
      return "";
    },

    contactReportsPermission: function ({ field, row }) {
      if (field === "MailStatus" && row.MailColour) {
        return row.MailColour.toLowerCase();
      } else if (field === "EmailStatus" && row.EmailColour) {
        return row.EmailColour.toLowerCase();
      } else if (field === "EmailStatus" && row.EmailColour) {
        return row.EmailColour.toLowerCase();
      } else if (field === "SampleReportStatus" && row.SampleReportColour) {
        return row.SampleReportColour.toLowerCase();
      } else if (field === "ExcelStatus" && row.ExcelColour) {
        return row.ExcelColour.toLowerCase();
      } else if (field === "MachineReportStatus" && row.MachineReportColour) {
        return row.MachineReportColour.toLowerCase();
      } else if (field === "XmlStatus" && row.XmlColour) {
        return row.XmlColour.toLowerCase();
      } else if (field === "SmsStatus" && row.SmsColour) {
        return row.SmsColour.toLowerCase();
      } else if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "Archived") {
        if (row.Archived === "Yes") return "red";
      } else if (field === "Source") {
        if (row.IsKomatsu) return "blue";
        if (row.IsPortalUser) return "orange";
        if (row.IsLabcheckUser) return "green";
        else return "brown";
      } else if (field === "ContactRole") {
        if (row.ContactRole === "Superuser") return "purple";
      }
      else if (field === "Primary") {
        if (row.Primary === "Yes") return "purple";
      }
      else if (field === "Username") {
        if (row.Primary === "Yes") return "purple";
      }
      else if (field === "Mobile") {
        if (row.Mobile?.length > 0) {
          if (row.Primary === "Yes") return "purple";
          else return ""
        }
        else return "red";
      }
      return "";
    },

    contactSubscriptionsTable: function ({ field, row }) {
      if (field === "IsWeekly") {
        if (row.IsWeekly === true) return "green";
      } else if (field === "IsMonthly") {
        if (row.IsMonthly === true) return "green";
      } else if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      }
      return "";
    },

    unitHourHistory: function ({ field, row }) {
      if (field === "SampleNumber") {
        if (row.AlertColour !== null) return row.AlertColour.toLowerCase();
      } else if (field === "Component") {
        if (row.IsEvent) return "teal";
      } else if (field === "Event") {
        if (row.IsEvent) return "teal";
      } else if (field === "Sampled") {
        if (row.IsEvent) return "teal";
      } else if (field === "MeterChanged") {
        if (row.IsEvent && row.IsMeterChange) return "violetred";
      } else if (field === "Component") {
        if (row.IsEvent && row.IsMeterChange) return "violetred";
      } else if (field === "Event") {
        if (row.IsEvent && row.IsMeterChange) return "violetred";
      } else if (field === "Sampled") {
        if (row.IsEvent && row.IsMeterChange) return "violetred";
      } else if (field === "ComponentChanged") {
        if (row.IsEvent && row.isComponentChange) return "orange";
      } else if (field === "Component") {
        if (row.IsEvent && row.isComponentChange) return "orange";
      } else if (field === "Event") {
        if (row.IsEvent && row.isComponentChange) return "orange";
      } else if (field === "Sampled") {
        if (row.IsEvent && row.isComponentChange) return "orange";
      } else if (field === "FluidChanged") {
        if (row.IsEvent && row.IsFluidChange) return "teal";
      }
      return "";
    },

    missedSamplesTable: function ({ field, row }) {
      if (field === "missed1") {
        if (row.missed1 > 0) return "red";
      } else if (field === "missed2") {
        if (row.missed2 > 0) return "red";
      } else if (field === "missed3") {
        if (row.missed3 > 0) return "red";
      } else if (field === "missed4") {
        if (row.missed4 > 0) return "red";
      } else if (field === "missed5") {
        if (row.missed5 > 0) return "red";
      } else if (field === "missed6") {
        if (row.missed6 > 0) return "red";
      } else if (field === "hold1") {
        if (row.hold1 > 0) return "red";
      } else if (field === "hold2") {
        if (row.hold2 > 0) return "red";
      } else if (field === "hold3") {
        if (row.hold3 > 0) return "red";
      } else if (field === "hold4") {
        if (row.hold4 > 0) return "red";
      } else if (field === "hold5") {
        if (row.hold5 > 0) return "red";
      } else if (field === "hold6") {
        if (row.hold6 > 0) return "red";
      }
      return "";
    },

    emailSelectedSamples: function ({ field, row }) {
      if (field === "Product" && row.ProductColour) {
        return row.ProductColour.toLowerCase();
      } else if (field === "ItemNumber") {
        if (row.ItemColour !== null) return row.ItemColour.toLowerCase();
      }
      else if (field === "Complete") {
        if (row.Complete === 'No') return "red";
      }
      return "";
    },
  };
}
