import { Typography } from "@mui/material";
import moment from "moment";

export const TrayDateFormat = "YYYYMMDD";
export const UserDateFormat = "DD/MM/YYYY";
export const DateFormat = "YYYY-MM-DD";
export const UserDateTimeFormat = "DD/MM/YYYY HH:mm:ss";
export const UserDateTimeFormat2 = "MM/DD/YYYY HH:mm A";
export const UserDayDateTimeFormat = "dddd DD/MM/YYYY HH:mm:ss";
export const UserDayDateFormat = "dddd DD/MM/YYYY";
export const UserDayDateFormat3 = "ddd DD/MM/YYYY";
export const UserDateTimeFormat1 = "DD/MM/YYYY HH:mm";
export const UserISOFormat = "YYYY-MM-DDTHH:mm:ss";

export function CheckNumber(val, defaultVal) {
  if (val || val === 0) {
    return val;
  }
  return defaultVal || "";
}

export const toUtc = (date, addDay) => {
  if (date) {
    const utc = moment(date).add(new Date().getTimezoneOffset(), "minutes");
    if (addDay) return moment(utc).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
    return utc.format("YYYY-MM-DDTHH:mm:ss");
  }
};
export const toDateTimeFormat1 = (isoTime) => {
  if (isoTime) {
    // return moment(isoTime).format(UserDateTimeFormat);
    return moment.utc(isoTime).format(UserDateTimeFormat1);
  }
};
export const toDateTimeFormat = (isoTime) => {
  if (isoTime) {
    // return moment(isoTime).format(UserDateTimeFormat);
    return moment.utc(isoTime).format(UserDateTimeFormat);
  }
};
export const toLocalDateTime = (isoTime) => {
  if (isoTime) {
    // return moment(isoTime).format(UserDateTimeFormat);
    return moment.utc(isoTime).local().format(UserDateTimeFormat);
  }
};
export const toLocalDateTime2 = (isoTime) => {
  if (isoTime) {
    return moment.utc(isoTime).local().format(UserDateTimeFormat2);
  }
};
export const toLocalDateTime3 = (isoTime) => {
  if (isoTime) {
    // return moment(isoTime).format(UserDateTimeFormat);
    return moment(isoTime, TrayDateFormat).format(UserISOFormat);
  }
};
export const toLocalDayDateTime = (isoTime) => {
  if (isoTime) {
    return moment.utc(isoTime).local().format(UserDayDateTimeFormat);
  }
};

export const toLocalDayDate = (isoTime) => {
  if (isoTime) {
    return moment.utc(isoTime).local().format(UserDayDateFormat);
  }
};

export const toLocalDayDate2 = (dateString) => {
  if (dateString) {
    return moment(dateString, TrayDateFormat).format(UserDayDateFormat);
  }
};
export const toLocalDayDate3 = (dateString) => {
  if (dateString) {
    return moment(dateString, TrayDateFormat).format(UserDayDateFormat3);
  }
};
export const toLocalDate = (isoTime) => {
  if (isoTime) {
    return moment.utc(isoTime).local().format(UserDateFormat);
  }
};
//output will be iso
export const toIsoFormat = (dateString) => {
  if (dateString) {
    return moment(dateString, "YYYYMMDD").format("YYYY-MM-DDTHH:mm:ss");
  }
};

function addHours(date, hours) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  date.setTime(date.getTime() + hoursToAdd);
  return date;
}

//output will be iso
export const dateToISO = (dateString) => {
  if (dateString) {
    if (dateString?.includes("T")) {
      return dateString
    }
    else {
      var dateParts = dateString.split("/");
      var dateObject = dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2];
      var newDate1 = addHours(new Date(dateObject), 12);
      var dateObject1 = moment(newDate1).format("YYYY-MM-DDTHH:mm:ss");
      return dateObject1;
    }
  }
};

export const isoToDate = (isoTime) => {
  if (isoTime) {
    if (isoTime?.includes("T")) {
      // return moment.utc(isoTime).local().format(UserDateFormat);
      return moment.utc(isoTime).format(UserDateFormat);
    }
    else {
      return isoTime;
    }
  }
};

//output will be dd/mm/yyyy
export const toLocalDate2 = (dateString) => {
  if (dateString) {
    return moment(dateString, TrayDateFormat).format(UserDateFormat);
  }
};
//output will be YYYYMMDD
export const toBasicLocalDate = (dateString) => {
  if (dateString) {
    const inputDate = moment(dateString);
    const outputDate = inputDate.format(TrayDateFormat);
    return outputDate;
  }
};
export const toYMD = (dateString) => {
  if (dateString) {
    const outputDate = moment(dateString).format(DateFormat);
    return outputDate;
  }
};

export const getStartLetter = (letter) => {
  if (letter?.length > 0) {
    const fLetter = letter.toUpperCase();
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    let result = alphabet.indexOf(fLetter);
    return (result + 1);
  }
  else return 0;
}

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export function applyLineBreaks(str, check) {
  let para = str.split("\r\n");
  if (para.length > 0) para.shift();
  return para.map((item, idx) => {
    // if (item === "") return null;
    return (
      <Typography
        key={idx}
        style={check === true ? {} : {
          color: item.includes("uct: KOWA")
            ? "#253fc8"
            : item.includes("uct: Labcheck")
              ? "#009933"
              : "",
        }}
      >
        {item}
        <br />
      </Typography>
    );
  });
}